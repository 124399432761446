import { ConsoleLogger, LOG_LEVEL } from "@opr-finance/feature-console-logger";
import { T_Application, T_ApplicationSent } from "../types/general";
import { T_BrokerProxyApplication, T_PartnerApplication } from "../types/partner";
import { extractBrokerProxyApplication } from "./brokerProxyApplication";

const logger = new ConsoleLogger({ level: LOG_LEVEL });

export async function sendApplication(data: T_Application): Promise<T_ApplicationSent> {
    const result = await fetch(`${process.env.REACT_APP_FRENDS_URL}/v1/fi/application`, {
        method: "POST",
        body: JSON.stringify(data),
    });
    //const applicationData = await result.json();
    if (result.status >= 200 && result.status < 300) {
        return {
            status: true,
            type: "general",
        };
    } else {
        return {
            status: false,
            type: "general",
        };
    }
}

export async function sendPartnerApplication(
    data: T_BrokerProxyApplication
): Promise<T_ApplicationSent> {
    const result = await fetch(
        `${process.env.REACT_APP_FRENDS_BROKER_PROXY_URL}/v1/fortis/application`,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "X-ApiKey": `${process.env.REACT_APP_API_KEY_FRENDS}`,
            },
            body: JSON.stringify(data),
        }
    );
    logger.log("partner-test-result", result);
    if (result.status >= 200 && result.status < 300) {
        return {
            status: true,
            type: "general",
        };
    } else {
        return {
            status: false,
            type: "general",
        };
    }
}
export async function sendRescoringApplication(data: T_Application): Promise<T_ApplicationSent> {
    const result = await fetch(`${process.env.REACT_APP_FRENDS_URL}/v1/fi/increase-credit-limit`, {
        method: "POST",
        body: JSON.stringify(data),
    });
    if (result.status >= 200 && result.status < 300) {
        return {
            status: true,
            type: "rescoring",
        };
    } else {
        return {
            status: false,
            type: "rescoring",
        };
    }
}
